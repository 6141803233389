import React  from 'react';
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

const styles = {
    cardCategoryWhite: {
    },
    cardTitleWhite: {
    }
  };
  
  const useStyles = makeStyles(styles);
  
  export default function ViewUsuarios(props) {
    const formatDate = (dateString) => new Date(dateString).toLocaleString('pt-br', { year: 'numeric', month: '2-digit', day: '2-digit' });

    const replaceStringAsterisco = (value) => {
        let nome = value.trim().split(" ");
        let nome_final = "";
        //console.log(nome);

        if(nome.length == 2) {
            let name_temp = "";
            for(let i = 0; i < nome[1].length; i++) {
                name_temp = name_temp + "*";
            }
            nome_final = nome[0] + " " + name_temp;
        } else {
            let primeiro_nome = "";
            let ultimo_nome = "";
            let name_temp = "";
            nome.forEach(function(name, index) {
                
                if(index == 0) {
                    primeiro_nome = name;
                } if(index == (nome.length - 1)) { 
                    ultimo_nome = name;
                } else {
                    name_temp = name_temp + " ";
                    for(let i = 0; i < name.length; i++) {
                        name_temp = name_temp + "*";
                    }
                }

                if(index == (nome.length - 1)) {
                    nome_final = primeiro_nome + " " + name_temp + " " + ultimo_nome;
                    console.log(nome_final);
                }
            });
        }
        //return value;
        return nome_final.toUpperCase();
    }

    return (
        <>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Nome</TableCell>
                            <TableCell align="center">Data de Cadasatro</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {props.users.map((user, i) => {
                        return (
                            <TableRow key={i}>
                                <TableCell component="th" scope="row">
                                    {replaceStringAsterisco(user.Nome)}
                                </TableCell>
                                <TableCell component="th" scope="row" align="center">
                                    {formatDate(user.DataCadastro)}
                                </TableCell>
                                
                            
                            </TableRow>
                        );
                    })}
                       
                    </TableBody>
                </Table>
                </TableContainer>   
        </>
      );
  }