import React, { useEffect } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";

// material-ui components
import { makeStyles } from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
// core components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import styles from "assets/jss/material-dashboard-react/components/customTabsStyle.js";

const useStyles = makeStyles(styles);

export default function CustomTabs(props) {
  const [value, setValue] = React.useState(0);
  const { headerColor, plainTabs, tabs, navigationButtons, form, handleClick, title, rtlActive } = props;
  const handleChange = (event, value) => {
    setValue(value);
  };

  /*useEffect((event) => {
    
    if(handleClick) {
      let tab = value + 1;
      console.log("tab", tab);
      console.log("tabs", tabs.length);
      if(tab > tabs.length) {
        handleClick(event, tab, true);
      }
    }
  }, [value]);*/

  const handleClickNextBtn = (event) => { 
    let tab = value + 1;
    
    if(!handleClick){
      if(tab < tabs.length) {
        setValue(tab);
      }
    } else if(!handleClick(event, tab)) {
      if(tab < tabs.length) {
        setValue(tab);
      } else if(tab >= tabs.length) {
        handleClick(event, tab, true);
      }
    }
  };

  const handleClickBackBtn = (event) => { 
      let tab = value - 1;
      setValue(tab);
  }; 

  const classes = useStyles();
  
  const cardTitle = classNames({
    [classes.cardTitle]: true,
    [classes.cardTitleRTL]: rtlActive
  });
  return (
    <Card plain={plainTabs}>
      <CardHeader color={headerColor} plain={plainTabs}>
        {title !== undefined ? <div className={cardTitle}>{title}</div> : null}
        <Tabs
          value={value}
          onChange={handleChange}
          classes={{
            root: classes.tabsRoot,
            indicator: classes.displayNone,
            scrollButtons: classes.displayNone
          }}
          variant="scrollable"
          scrollButtons="auto"
        >
          {tabs.map((prop, key) => {
            var icon = {};
            if (prop.tabIcon) {
              icon = {
                icon: <prop.tabIcon />
              };
            }
            return (
              <Tab
                classes={{
                  root: classes.tabRootButton,
                  selected: classes.tabSelected,
                  wrapper: classes.tabWrapper
                }}
                key={key}
                label={prop.tabName}
                {...icon}
              />
            );
          })}
        </Tabs>
      </CardHeader>
      <CardBody>
        {tabs.map((prop, key) => {
          if (key === value) {
            return <div key={key}>{prop.tabContent}</div>;
          }
          return null;
        })}

        {navigationButtons !== null && navigationButtons ?
          <GridContainer>
              <GridItem xs={12} sm={12} md={5}>
                  { value !== 0 ?
                  <Button
                      type="button"
                      fullWidth
                      variant="contained"
                      color="secondary"
                      className={classes.backBtn}
                      onClick={handleClickBackBtn}
                  >
                      Voltar
                  </Button>
                : null }
              </GridItem>
              <GridItem xs={12} sm={12} md={2}></GridItem>
              <GridItem xs={12} sm={12} md={5}>
                  
                  <Button
                      type="button"
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.nextBtn}
                      onClick={handleClickNextBtn}
                  >
                      { (value + 1) < tabs.length ? 'Avançar' : 'Cadastrar' }
                  </Button>
                

                

              </GridItem>
          </GridContainer>
        : null }
      </CardBody>
    </Card>
  );
}

CustomTabs.propTypes = {
  headerColor: PropTypes.oneOf([
    "warning",
    "success",
    "danger",
    "info",
    "primary",
    "rose"
  ]),
  title: PropTypes.string,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      tabName: PropTypes.string.isRequired,
      tabIcon: PropTypes.object,
      tabContent: PropTypes.node.isRequired
    })
  ),
  rtlActive: PropTypes.bool,
  plainTabs: PropTypes.bool
};
