import {
  successColor,
  whiteColor,
  grayColor,
  hexToRgb
} from "assets/jss/material-dashboard-react.js";

const dashboardStyle = {
  successText: {
    color: successColor[0]
  },
  upArrowCardCategory: {
    width: "16px",
    height: "16px",
    fontFamily: "Montserrat, sans-serif",
  },
  stats: {
    fontFamily: "Montserrat, sans-serif",
    color: grayColor[0],
    display: "inline-flex",
    fontSize: "12px",
    lineHeight: "22px",
    "& svg": {
      top: "4px",
      width: "16px",
      height: "16px",
      position: "relative",
      marginRight: "3px",
      marginLeft: "3px"
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      top: "4px",
      fontSize: "16px",
      position: "relative",
      marginRight: "3px",
      marginLeft: "3px"
    },
    padding: '0px 25px',
  },
  cardCategory: {
    color: grayColor[0],
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    paddingTop: "10px",
    marginBottom: "0",
    fontFamily: "Montserrat, sans-serif",
  },
  cardCategory2: {
    color: '#000',
    margin: "0",
    fontSize: "20px",
    marginTop: "0",
    marginBottom: "0",
    textAlign: 'left',
    paddingTop: 5,
    fontFamily: "Montserrat, sans-serif",
    fontWeight: 500,
  },
  cardCategoryWhite: {
    color: "rgba(" + hexToRgb(whiteColor) + ",.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
    fontFamily: "Montserrat, sans-serif",
  },
  cardTitle: {
    color: grayColor[2],
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "600",
    fontFamily: "Montserrat, sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: '#000',
      lineHeight: "1",
      fontWeight: 300
    }
  },
  cardTitleWhite: {
    color: whiteColor,
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: grayColor[1],
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  boxInfos: {
    color: 'red'
  },
  cardFooter: {
    margin: '15px 15px 0px',
  },
  legenda: {
    listStyle: 'none',
  },
  legendaLI: {
    float: 'left',
    marginLeft: 20
  },
  boxIcon: {
    marginTop: '0 !important',
    borderRadius: '45px !important',
    boxShadow: 'none'
  },
  card: {
    boxShadow: 'none',
    backgroundColor: '#FFF',
    paddingTop: '20px',
    paddingBottom: '20px !important',
    borderTop: '5px solid #03A9F4!important',
  },
  cardTitleCalendario: {
    fontFamily: "Montserrat, sans-serif",
    fontSize: '25px',
    fontWeight: 500,
    textAlign: 'center',
    marginTop: '15px'
  },
  teste: {
    padding: 0
  },
  carBottom0: {
    marginBottom: 0
  },
  cardHistoricoTransacoes: {
    marginTop: 0,
    
    boxShadow: '0 0 black',
    backgroundColor: '#FFF'
  },
  cardHistoricoTransacoesBorderTop: {
    borderTop: '10px solid #e2e2e2',
  },
  blackDiv: {
    color: '#000', 
    fontSize: '15px'
  },
  titleHeader: {
    fontSize: '25px',
    marginBottom: '0px',
    textAlign: 'center',
    fontWeight: 'bold'
  }
};

export default dashboardStyle;
