/*!

=========================================================
* Material Dashboard React - v1.8.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import Person from "@material-ui/icons/Person";
import LibraryBooks from "@material-ui/icons/LibraryBooks";
import BubbleChart from "@material-ui/icons/BubbleChart";
import LocationOn from "@material-ui/icons/LocationOn";
import Notifications from "@material-ui/icons/Notifications";
import Unarchive from "@material-ui/icons/Unarchive";
import Language from "@material-ui/icons/Language";
import ExitToApp from "@material-ui/icons/ExitToApp";
import DescriptionIcon from '@material-ui/icons/Description';
// core components/views for Admin layout
import DashboardPage from "views/Dashboard/Dashboard.js";
import UserProfile from "views/UserProfile/UserProfile.js";
import TotalUsers from "views/ViewUsers/TotalUsers";
import TableList from "views/TableList/TableList.js";
import Typography from "views/Typography/Typography.js";
import Icons from "views/Icons/Icons.js";
import Maps from "views/Maps/Maps.js";
import NotificationsPage from "views/Notifications/Notifications.js";
import UpgradeToPro from "views/UpgradeToPro/UpgradeToPro.js";
import Login from "views/Login/Login";
import Sair from "views/Login/Sair";
import Termos from 'views/Termos/Termos';
// core components/views for RTL layout
import RTLPage from "views/RTLPage/RTLPage.js";

const dashboardRoutes = [
  {
    path: "/login",
    name: "Sair",
    rtlName: "قائمة الجدول",
    icon: ExitToApp,
    component: Login,
    layout: "",
    invisible: true,
    enableBack: false
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: Dashboard,
    component: DashboardPage,
    layout: "/admin"
  },
  {
    path: "/user",
    name: "Perfil",
    rtlName: "ملف تعريفي للمستخدم",
    icon: Person,
    component: UserProfile,
    layout: "/admin"
  },
  {
    path: "/total-users",
    name: "Total de Usuários",
    rtlName: "ملف تعريفي للمستخدم",
    icon: Person,
    component: TotalUsers,
    layout: "/admin",
    invisible: true
  },
  {
    path: "/table",
    name: "Recebíveis",
    rtlName: "قائمة الجدول",
    icon: "content_paste",
    component: TableList,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/termos/",
    name: "Termos",
    rtlName: "قائمة الجدول",
    icon: DescriptionIcon,
    component: Termos,
    layout: "/admin",
    invisible: false,
    enableBack: true
  },
  {
    path: "/sair",
    name: "Sair",
    rtlName: "التطور للاحترافية",
    icon: Unarchive,
    component: Sair,
    layout: "/admin"
  }
  /* {
    path: "/upgrade-to-pro",
    name: "Upgrade To PRO",
    rtlName: "التطور للاحترافية",
    icon: Unarchive,
    component: UpgradeToPro,
    layout: "/admin"
  } */
];

export default dashboardRoutes;
