import React, { useEffect, useState } from "react";

// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
import { Badge } from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
//import ContentCopy from "@material-ui/icons/Content";

import BlockIcon from '@material-ui/icons/Block';
import DoneIcon from '@material-ui/icons/Done';

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Table2 from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import LoadingFull from "components/Loading/LoadingFull";

import moment from "moment";
import MomentUtils from '@date-io/moment';
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import copy from "copy-to-clipboard";  
import "moment/locale/pt-br";

import api from 'services/api';
import checaLogin from "services/checaLogin";
import './styles.css';


import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";

const useStyles = makeStyles(styles);

const StyledBadge = withStyles((theme) => ({
  badge: {
    top: 30,
    right: 20,
    minWidth: 5,
    height: 5,
    padding: '0 2px',
    backgroundColor: '#43a047'
  },
}))(Badge);

const LegendBadge1 = withStyles((theme) => ({
  badge: {
    minWidth: 10,
    height: 12,
    backgroundColor: '#8c8888',
    marginRight: 6
  },
}))(Badge);

const LegendBadge2 = withStyles((theme) => ({
  badge: {
    minWidth: 10,
    height: 12,
    backgroundColor: '#f44336',
    marginRight: 6
  },
}))(Badge);

const StyledTableCell = withStyles((theme) => ({
  head: {
    color: "#2196f3",
    fontWeight: 400,
    borderBottom: 0
  },
  body: {
    color: "#f44336",
  },
}))(TableCell);

const DefaultStyledTableCell = withStyles((theme) => ({
  body: {
    color: "#8c8888",
  },
}))(TableCell);

const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersBasePicker: {
      pickerView: {
        width: '100%',
        maxWidth: '100%'
      }
    },
    MuiPickersCalendarHeader: {
      iconButton: {
        '&:hover': {
          backgroundColor: '#00acc1',
          color: '#FFFFFF'
        }
      },
      daysHeader: {
        backgroundColor: '#eeeeee',
        padding: 5,
        borderRadius: 20
      },
    },
    MuiPickersDay: {
      day: {
        //color: lightBlue.A700,
        '&:hover': {
          backgroundColor: '#00acc1',
          color: '#FFFFFF'
        }
      },
      daySelected: {
        backgroundColor: '#00acc1',
      },
      dayDisabled: {
        color: 'eeeeee',
      },
      current: {
        color: '#00acc1',
      },
    },
  },
});

export default function Dashboard(props) {
  const [totalUsers, setTotalUsers] = useState(0);
  const [totalUsersAtivos, setTotalUsersAtivos] = useState(0);
  const [totalUsersBloqueados, setTotalUsersBloqueados] = useState(0);
  const [date, changeDate] = useState(new Date());
  const [datasCMarcar, setDatasCMarcar] = useState([]);
  const [valorPrevisto, setValorPrevisto] = useState(0.00);
  const [historicoTransacoes, setHistoricoTransacoes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [valorPrevistoMes, setValorPrevistoMes] = useState(0.00);
  const [mesAtualEscrito, setMesAtualEscrito] = useState(moment().format("MMMM"));

  const matches = useMediaQuery('(max-width:600px)');

  moment.locale("pt-br");

  checaLogin(props);

  let linkAfiliacao = localStorage.getItem("CDLAfiliadoLink");

  useEffect(() => {
    let mes_atual = new Date().getMonth() + 1;
    montaCalendario(mes_atual);
    handlebuscaTotalUsuarioPorAfiliado();
  }, [props]);

  useEffect(() => {
    let data_calendario = moment(date, "YYYY-MM-DD", true).format("YYYY-MM-DD");
    handleChangeDate(data_calendario);
  }, [date]);

  async function montaCalendario(mes) {
    let id = localStorage.getItem("CDLAfiliadoId");
    let perfilSistema = localStorage.getItem("CDLAfiliadoPerfilSistema");

    let formdata = new FormData();
    formdata.append('function', 'blocos_dashboard_calendar');
    formdata.append('id', id);
    formdata.append('mes', mes);
    formdata.append('perfil_sistema', perfilSistema);
    
    await api.post('parceiro.php', formdata)
    .then(function (response) {
      //console.log("Monta calendário", response);
      setValorPrevisto(0.00) // ZERA VALOR PREVISTO DIA
      setDatasCMarcar(response.data.result.Calendar)
      setValorPrevistoMes(response.data.ValorTotalMes)

      let arrayDados = [];
      if(typeof response.data.result !== 'undefined' && response.data.result.length > 0) {
        //console.log("passou aqui");
        if(response.data.result.HistoricoTransacoes.length > 0 || response.data.result.HistoricoTransacoesEstornadas.length > 0) {
          response.data.result.HistoricoTransacoes.map((valueObj, index, array) => {
            let data_formatada = moment(valueObj.Data, "YYYY-MM-DD", true).format("DD/MM/YYYY");
            let valor_print = formataValor(valueObj.Valor, true) + " - " + valueObj.QtdParcela + "x de " + formataValor(valueObj.ValorAmount, true);
            arrayDados.push({nome: valueObj.Nome, email: valueObj.Email, porcentagem: valueObj.Porcentagem + "%", parcelas: valueObj.Parcelas, valor_parcela: formataValor(valueObj.ValorParcela, true), valor_total: formataValor(valueObj.ValorAmount, true), data_compra: data_formatada});
          });
        }
      }
      setHistoricoTransacoes(arrayDados);

    })
    .catch(function (error) {
      // handle error
      console.log(error);
    })
    .finally(function () {
      // always executed
      setLoading(false);
    });
  }

  function formataValor(valor, cifrao = false) {
    if (cifrao) {
      return parseFloat(valor).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    } else {
      return parseFloat(valor).toLocaleString('pt-BR', { maximumFractionDigits: 2 });
    }
  }

  async function handleChangeDate(data) {
    setLoading(true);
    let id = localStorage.getItem("CDLAfiliadoId");
    let perfilSistema = localStorage.getItem("CDLAfiliadoPerfilSistema");

    let formdata = new FormData();
    formdata.append('function', 'blocos_dashboard_valor_previsto');
    formdata.append('id', id);
    formdata.append('data', data);
    formdata.append('perfil_sistema', perfilSistema);
    
    await api.post('parceiro.php', formdata)
    .then(function (response) {
      //console.log("change date", response);
      setValorPrevisto(response.data.result.ValorPrevisao);
      let arrayDados = [];
      if(response.data.result.HistoricoTransacoes.length > 0 || response.data.result.HistoricoTransacoesEstornadas.length > 0) {
        response.data.result.HistoricoTransacoes.map((valueObj, index, array) => {
          let data_formatada = moment(valueObj.Data, "YYYY-MM-DD", true).format("DD/MM/YYYY");
          arrayDados.push({nome: valueObj.Nome, email: valueObj.Email, porcentagem: valueObj.Porcentagem + "%", parcelas: valueObj.Parcelas, valor_parcela: formataValor(valueObj.ValorParcela, true), valor_total: formataValor(valueObj.ValorAmount, true), data_compra: data_formatada, tipo_transacao: ""});
        });
        //LÊ HISTÓRICO TRANSAÇÕES ESTORNADAS
        response.data.result.HistoricoTransacoesEstornadas.map((valueObj, index, array) => {
          let data_formatada = moment(valueObj.Data, "YYYY-MM-DD", true).format("DD/MM/YYYY");
          arrayDados.push({nome: valueObj.Nome, email: valueObj.Email, porcentagem: valueObj.Porcentagem + "%", parcelas: valueObj.Parcelas, valor_parcela: formataValor(valueObj.ValorParcela, true), valor_total: formataValor(valueObj.ValorAmount, true), data_compra: data_formatada, tipo_transacao: "estorno"});
        });
        setHistoricoTransacoes(arrayDados);
      } else {
        setHistoricoTransacoes(arrayDados);
      }
    })
    .catch(function (error) {
      // handle error
      console.error(error);
    })
    .finally(function () {
      // always executed
      setLoading(false);
    });
  }

  function handleMonthChange(date) {
    setLoading(true);
    setMesAtualEscrito(moment(date).format("MMMM"));
    let mes = moment(date, "YYYY-MM-DD").month() + 1;
    montaCalendario(mes);
  }

  function renderDayCalendar(day, selectedDate, isInCurrentMonth, dayComponent) {
    let data_calendario = day.format("YYYY-MM-DD");
    if (datasCMarcar != undefined) {
      if(datasCMarcar.includes(data_calendario)) { 
        return <StyledBadge badgeContent={""} >{dayComponent}</StyledBadge>;
      } else {
        return dayComponent;
      }
    } else {
      return dayComponent;
    }
    
  }

  async function handlebuscaTotalUsuarioPorAfiliado(data) {
    setLoading(true);
    let id = localStorage.getItem("CDLAfiliadoId");
    let perfilSistema = localStorage.getItem("CDLAfiliadoPerfilSistema");
    console.log(id);
    let formdata = new FormData();
    formdata.append('function', 'get_users_by_id_parceiro');
    formdata.append('id', id);
    formdata.append('perfil_sistema', perfilSistema);
    
    await api.post('parceiro.php', formdata)
    .then(function (response) {
      //console.log("response users", response);
      if(response.data.response == "success") {
        setTotalUsers(response.data.users.Total.TotalUsers);
        setTotalUsersAtivos(response.data.users.Ativos.TotalUsers);
        setTotalUsersBloqueados(response.data.users.Bloqueados.TotalUsers);
      }
    })
    .catch(function (error) {
      // handle error
      console.log(error);
    })
    .finally(function () {
      // always executed
      setLoading(false);
    });
  }

  const handleClickClientesTotal = (e) => {
    e.preventDefault();
    props.history.push('/admin/total-users');
  };

  const handleClickCopyLinkAfiliacao = (e) => {
    e.preventDefault();
    copy(linkAfiliacao);
    alert("Link copiado para área de transferência");
  };

  const classes = useStyles();
  return (
    <div>
      <LoadingFull display={loading} />
      <p><strong>Link de Divulgação:</strong> {linkAfiliacao} <a href="#" onClick={handleClickCopyLinkAfiliacao} title="Copiar link para área de transferência"> <span class="material-icons">content_copy</span></a></p>
      <h2 className={classes.titleHeader}>Usuários cadastrados através do seu link</h2>
      <GridContainer>
        <GridItem xs={12} sm={6} md={6}>
          <Card className={classes.card}>
            <CardHeader color="info" stats icon>
              <CardIcon color="info" className={classes.boxIcon}>
                <Icon>account_circle</Icon>
              </CardIcon>
              <p className={classes.cardCategory}>Total</p>
              <h3 className={classes.cardTitle}>
                <a href="#" onClick={handleClickClientesTotal}>{totalUsers}</a>
              </h3>
            </CardHeader>
            <CardFooter stats className={classes.cardFooter}>
              <div className={classes.blackDiv}>
                Para saber mais detalhes, clique no número total de usuários cadastrados
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6}>
          <Card className={classes.card}>
            <CardHeader color="success" stats icon>
              <CardIcon color="success" className={classes.boxIcon}>
                <DoneIcon />
              </CardIcon>
              <p className={classes.cardCategory}>Ativos</p>
              <h3 className={classes.cardTitle}>
                {totalUsersAtivos}
              </h3>
            </CardHeader>
            <CardFooter stats className={classes.cardFooter}>
              <div className={classes.stats}>
                Total
              </div>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={6} md={6}>
          <Card className={classes.card}>
            <CardHeader color="danger" stats icon>
              <CardIcon color="danger" className={classes.boxIcon}>
                <BlockIcon />
              </CardIcon>
              <p className={classes.cardCategory}>Bloqueados</p>
              <h3 className={classes.cardTitle}>
                {totalUsersBloqueados}
              </h3>
            </CardHeader>
            <CardFooter stats className={classes.cardFooter}>
              <div className={classes.stats}>
                Total
              </div>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer classes={{root: matches ? 'grid-container-tt' : ''}}>
        <GridItem xs={12} sm={12} md={12} classes={{root: matches ? 'grid-item-calendar-tt' : ''}}>
            <Card className={classes.carBottom0}>
              <h4 className={classes.cardTitleCalendario}>Calendário Financeiro de Vendas - {mesAtualEscrito}</h4>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6} className={classes.boxInfos} classes={{root: matches ? 'border-bottom-tt' : ''}}>
                    <Card style={{ backgroundColor: '#f9f9f9', paddingTop: 20, paddingBottom: 20 }}>
                      <CardHeader color="success" stats icon>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={6} className={classes.boxInfos}>
                            <p className={classes.cardCategory2}>Volume previsto p/ o dia</p>
                          </GridItem>
                          <GridItem xs={12} sm={12} md={6} className={classes.boxInfos}>
                            <h3 className={classes.cardTitle}>R$ {formataValor(valorPrevisto)}</h3>
                          </GridItem>
                        </GridContainer>
                      </CardHeader>
                    </Card>
                    <Card style={{ backgroundColor: '#f9f9f9', paddingTop: 20, paddingBottom: 20 }}>
                      <CardHeader color="success" stats icon>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={6} className={classes.boxInfos}>
                            <p className={classes.cardCategory2}>Volume previsto p/ o mês</p>
                          </GridItem>
                          <GridItem xs={12} sm={12} md={6} className={classes.boxInfos}>
                            <h3 className={classes.cardTitle}>R$ {formataValor(valorPrevistoMes)}</h3>
                          </GridItem>
                        </GridContainer>
                      </CardHeader>
                    </Card>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CardBody>
                      <ThemeProvider theme={materialTheme}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                          <DatePicker
                            disableToolbar={true}
                            orientation="landscape"
                            variant="static"
                            openTo="date"
                            value={date}
                            onChange={changeDate}
                            renderDay={renderDayCalendar}
                            onMonthChange={handleMonthChange}
                          />
                        </MuiPickersUtilsProvider>
                      </ThemeProvider>  
                    </CardBody>
                  </GridItem>
                </GridContainer>
              </CardBody>
              <CardFooter>
                <p className={classes.stats}>*Data de recebimento varia entre 5 dias para cima quanto 5 dias para baixo.</p>
              </CardFooter>
            </Card>
        </GridItem>
      </GridContainer>
      <GridContainer classes={{root: matches ? 'grid-container-tt' : ''}}>
        <GridItem xs={12} sm={12} md={12} classes={{root: matches ? 'grid-item-calendar-tt padding-top-0' : ''}}>
          <Card className={classes.cardHistoricoTransacoes, matches ? classes.cardHistoricoTransacoesBorderTop : ''}>
            <CardBody>
              {historicoTransacoes.length > 0 ? (
                <TableContainer component={Paper}>
                  <Table2  size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>Nome</StyledTableCell>
                        <StyledTableCell align="left">E-mail</StyledTableCell>
                        <StyledTableCell align="right">Porcentagem</StyledTableCell>
                        <StyledTableCell align="right">Parcela</StyledTableCell>
                        <StyledTableCell align="right">Comissão da parcela</StyledTableCell>
                        <StyledTableCell align="right">Valor da parcela</StyledTableCell>
                        <StyledTableCell align="right">Data da compra</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {historicoTransacoes.map((historico_transacao, index) => {
                        if(historico_transacao.tipo_transacao == "estorno") 
                          return <TableRow key={index}>
                              <StyledTableCell component="th" scope="row">
                                {historico_transacao.nome}
                              </StyledTableCell>
                              <StyledTableCell align="left">{historico_transacao.email}</StyledTableCell>
                              <StyledTableCell align="right">{historico_transacao.porcentagem}</StyledTableCell>
                              <StyledTableCell align="right">{historico_transacao.parcelas}</StyledTableCell>
                              <StyledTableCell align="right">{historico_transacao.valor_parcela}</StyledTableCell>
                              <StyledTableCell align="right">{historico_transacao.valor_total}</StyledTableCell>
                              <StyledTableCell align="right">{historico_transacao.data_compra}</StyledTableCell>
                          </TableRow>
                        else
                         return <TableRow key={index}>
                              <DefaultStyledTableCell component="th" scope="row">
                                {historico_transacao.nome}
                              </DefaultStyledTableCell>
                              <DefaultStyledTableCell align="right">{historico_transacao.email}</DefaultStyledTableCell>
                              <DefaultStyledTableCell align="right">{historico_transacao.porcentagem}</DefaultStyledTableCell>
                              <DefaultStyledTableCell align="right">{historico_transacao.parcelas}</DefaultStyledTableCell>
                              <DefaultStyledTableCell align="right">{historico_transacao.valor_parcela}</DefaultStyledTableCell>
                              <DefaultStyledTableCell align="right">{historico_transacao.valor_total}</DefaultStyledTableCell>
                              <DefaultStyledTableCell align="right">{historico_transacao.data_compra}</DefaultStyledTableCell>
                          </TableRow>
                      } ) }
                    </TableBody>
                  </Table2>
                </TableContainer>
              ) : (
                <Alert severity="info">Sem transações para este dia!</Alert>
              )}
            </CardBody>
            <CardFooter>
              <GridContainer classes={{root: classes.cardFooter}}>
                <GridItem xs={12} sm={6} md={6}>
                  <div className={classes.stats}>
                    Histórico diário
                  </div>
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <ul className={classes.legenda}>
                    <li className={classes.legendaLI}><LegendBadge1 badgeContent={""} color="primary"></LegendBadge1> Prevista para pagamento</li>
                    <li className={classes.legendaLI}><LegendBadge2 badgeContent={""} color="primary"></LegendBadge2> Cancelada/Estornada</li>
                  </ul>
                </GridItem>
              </GridContainer>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
