import React  from 'react';
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import classNames from 'classnames';

const styles = {
    cardCategoryWhite: {
    },
    cardTitleWhite: {
    },
    rowHeader: {
        backgroundColor: '#03a9f5'
    },
    th: {
        color: '#FFF'
    },
    cellBold: {
        fontWeight: '900',
        textAlign: 'center'
    }
  };
  
  const useStyles = makeStyles(styles);
  
  export default function ViewTabelaComissionamento(props) {
    const formatDate = (dateString) => new Date(dateString).toLocaleString('pt-br', { year: 'numeric', month: '2-digit', day: '2-digit' });
    let dias_t1 = ['01','02','03','04','05','06','07','08','09',10,11,12,13,14,15];
    let dias_t2 = [16,17,18,19,20,21,22,23,24,25,26,27,28,29,30];

    const classes = useStyles();

    return (
        <>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                        <TableRow className={classes.rowHeader}>
                            <TableCell className={classes.th}>Dia</TableCell>
                            {dias_t1.map((dia, i) => {
                                return (
                                    <TableCell align="center" key={i} className={classes.th}>{dia}</TableCell>
                                );
                            })}
                            
                            
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    
                            <TableRow>
                                <TableCell component="th" scope="row" className={classes.cellBold}>
                                    Quantidade de Cadastros
                                </TableCell>
                                {dias_t1.map((dia, i) => 
                                    props.days[dia] !== undefined ? 
                                    <TableCell component="th" scope="row" align="center" key={i}>
                                        {props.days[dia].Total}
                                    </TableCell> 
                                    : <TableCell component="th" scope="row" align="center" key={i}>
                                        {0}
                                    </TableCell>
                                )}
                                
                                
                            
                            </TableRow>

                            <TableRow key={0}>
                                <TableCell component="th" scope="row" className={classes.cellBold}>
                                    Valores Dia
                                </TableCell>
                                {dias_t1.map((dia, i) => 
                                    props.days[dia] !== undefined ? 
                                    <TableCell component="th" scope="row" align="center" key={i}>
                                        R$ {props.days[dia].Valor}
                                    </TableCell> 
                                    : <TableCell component="th" scope="row" align="center" key={i}>
                                        {'R$ 0,00'}
                                    </TableCell>
                                )}
                                
                            
                            </TableRow>
                        
                       
                    </TableBody>
                </Table>
            </TableContainer>   

            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                        <TableRow className={classes.rowHeader}>
                            <TableCell className={classes.th}>Dia</TableCell>
                            {dias_t2.map((dia, i) => {
                                return (
                                    <TableCell align="center" key={i} className={classes.th}>{dia}</TableCell>
                                );
                            })}
                            
                            
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    
                            <TableRow>
                                <TableCell component="th" scope="row" className={classes.cellBold}>
                                    Quantidade de Cadastros
                                </TableCell>
                                {dias_t2.map((dia, i) => 
                                    props.days[dia] !== undefined ? 
                                    <TableCell component="th" scope="row" align="center" key={i}>
                                        {props.days[dia].Total}
                                    </TableCell> 
                                    : <TableCell component="th" scope="row" align="center" key={i}>
                                        {0}
                                    </TableCell>
                                )}
                                
                            
                            </TableRow>

                            <TableRow key={0}>
                                <TableCell component="th" scope="row" className={classes.cellBold}>
                                    Valores Dia
                                </TableCell>
                                {dias_t2.map((dia, i) => 
                                    props.days[dia] !== undefined ? 
                                    <TableCell component="th" scope="row" align="center" key={i}>
                                        R$ {props.days[dia].Valor}
                                    </TableCell> 
                                    : <TableCell component="th" scope="row" align="center" key={i}>
                                        {'R$ 0,00'}
                                    </TableCell>
                                )}
                                
                            
                            </TableRow>
                        
                       
                    </TableBody>
                </Table>
            </TableContainer>
        </>
      );
  }