import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import InputLabel from "@material-ui/core/InputLabel";
import TextField from '@material-ui/core/TextField';
// core components
import InputMask from 'react-input-mask';
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import checaLogin from 'services/checaLogin';
import ViewUsuarios from '../../components/Views/ViewUsuarios';
import ViewTabelaComissionamento from '../../components/Views/ViewTabelaComissionamento';
import validarData from "utils/validaData";
import LoadingFull from 'components/Loading/LoadingFull';
import api from 'services/api';
import moment from "moment";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  },
  btnFilter: {
    marginTop: '10px'
  },
  bodyComissionamento: {
    textAlign: 'center'
  },
  imgComissionamento: {
    width: '100%'
  },
  comission_total: {
    textAlign: 'center',
    fontSize: '20px',
    fontWeight: 600
  }
};

const useStyles = makeStyles(styles);

export default function TotalUsers(props) {
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [initDate, setInitDate] = useState([]);
  const [endDate, setEndDate] = useState([]);
  const [comissionTable, setComissionTable] = useState([]);
  const [comissionTableTotal, setComissionTableTotal] = useState('0,00');
  const [mesAtualEscrito, setMesAtualEscrito] = useState(moment().format("MMMM"));

  const [erroInitDate, setErroInitDate] = useState(false);
  const [mensagemErroInitDate, setMensagemErroInitDate] = useState("");
  const [erroEndDate, setErroEndDate] = useState(false);
  const [mensagemErroEndDate, setMensagemErroEndDate] = useState("");

  checaLogin(props);

  useEffect(() => {
      let id = localStorage.getItem('CDLAfiliadoId');
      
      let formdata = new FormData();
      formdata.append('function', 'get_usuarios_total_parceiro_by_id');
      formdata.append('id', id);

      api.post('parceiro.php', formdata)
      .then(function (response) {
        //console.log(response);
        if(response.data.response == "success") {
          setUsers(response.data.users);
        }
        
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        // always executed
        setLoading(false);
      });
  }, []);

  useEffect(() => {
      let id = localStorage.getItem('CDLAfiliadoId');
      
      const d = new Date();
      let month = d.getMonth();

      let formdata = new FormData();
      formdata.append('function', 'get_table_comission');
      formdata.append('id', id);
      formdata.append('month', month + 1);

      api.post('parceiro.php', formdata)
      .then(function (response) {
        //console.log("aaa", response);
        if(response.data.response == "success") {
          setComissionTable(response.data.days);
          setComissionTableTotal(response.data.total_comission);
        }
        
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        // always executed
        setLoading(false);
      });
  }, []);


  const classes = useStyles();

  const handleChangeInitDate = (event) => {
    setInitDate(event.target.value);
    setErroInitDate(false);
    setMensagemErroInitDate("");
  };

  const handleChangeEndDate = (event) => {
    setEndDate(event.target.value);
    setErroEndDate(false);
    setMensagemErroEndDate("");
  };

  const handleClickFilter = (event) => {
    let filter = true;
    if(!validarData(initDate)) {
      filter = false;
      setErroInitDate(true);
      setMensagemErroInitDate("Data inicial no formato inválido");
    }
    if(!validarData(endDate)) {
      filter = false;
      setErroEndDate(true);
      setMensagemErroEndDate("Data final no formato inválido");
    }

    if(!filter) return null;

    let idbr = dateBRToUS(initDate);
    let edbr = dateBRToUS(endDate);

    setLoading(true);
    let id = localStorage.getItem('CDLAfiliadoId');
      
    let formdata = new FormData();
    formdata.append('function', 'get_usuarios_total_parceiro_by_id_with_data');
    formdata.append('id', id);
    formdata.append('init_date', idbr);
    formdata.append('end_date', edbr);

    api.post('parceiro.php', formdata)
    .then(function (response) {
      console.log(response);
      if(response.data.response == "success") {
        setUsers(response.data.users);
      }
      
    })
    .catch(function (error) {
      // handle error
      console.log(error);
    })
    .finally(function () {
      // always executed
      setLoading(false);
    });
  };

  function dateBRToUS(data) {
    let d = data.split("/");
    return d[2] + "-" + d[1] + "-" + d[0];
  }

  return (
    <>  
      <LoadingFull display={loading} />
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
              <CardHeader color="info">
                  <h4 className={classes.cardTitleWhite}>Tabela de comissionamento</h4>
              </CardHeader>
              <CardBody className={classes.bodyComissionamento}>
                <img src="https://condutorlegal.com.br/tabela-valores-leads-afiliados.jpg" alt="Tabela de comissionamento de afiliados" className={classes.imgComissionamento} />
                <h3>Quantidade de Usuários Cadastrados por Dia em {mesAtualEscrito[0].toUpperCase() + mesAtualEscrito.substring(1)}</h3>
                <ViewTabelaComissionamento days={comissionTable} />
              </CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}><p className={classes.comission_total}>Valor Total á receber: R$ {comissionTableTotal}</p></GridItem>
              </GridContainer>
            </Card>
        </GridItem>
      </GridContainer>

      

      <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                <Card>
                    <CardHeader color="info">
                        <h4 className={classes.cardTitleWhite}>Filtro</h4>
                    </CardHeader>
                    <CardBody>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                          <InputMask mask="99/99/9999"
                              maskChar=" "
                              variant="outlined"
                              value={initDate}
                              onChange={handleChangeInitDate}
                              required
                              >
                              {(inputProps) => <TextField {...inputProps}
                                  style={{ marginTop: '15px' }}
                                  id="data_inicial"
                                  label="Data Inicial"
                                  fullWidth
                                  error={erroInitDate}
                                  helperText={mensagemErroInitDate}
                              />}
                          </InputMask>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                          <InputMask mask="99/99/9999"
                              maskChar=" "
                              variant="outlined"
                              value={endDate}
                              onChange={handleChangeEndDate}
                              required
                              >
                              {(inputProps) => <TextField {...inputProps}
                                  style={{ marginTop: '15px' }}
                                  id="data_final"
                                  label="Data Final"
                                  fullWidth
                                  error={erroEndDate}
                                  helperText={mensagemErroEndDate}
                              />}
                          </InputMask>
                        </GridItem>
                      </GridContainer>
                      <Button 
                          className={classes.btnFilter}
                          type="button"
                          fullWidth
                          variant="contained"
                          color="primary"
                          onClick={handleClickFilter}
                      >
                          Filtrar
                      </Button>
                    </CardBody>
                </Card>
            </GridItem>
        </GridContainer>
        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                <Card>
                    <CardHeader color="warning">
                        <h4 className={classes.cardTitleWhite}>Total e Usuários</h4>
                    </CardHeader>
                    <CardBody>
                        <ViewUsuarios users={users} />
                    </CardBody>
                </Card>
            </GridItem>
        </GridContainer>

    </>
  );
}
